@import './theme.scss';

.general-card-total {
  color: $primary;
  font-size: 9rem;
  text-align: center;
  text-decoration: none !important;
  width: 100%;
}

.card-total-red {
  color: $danger;
}

.directory-contact-info {
  color: $primary;
}

.base-list {
  &.ant-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.ant-list-sm {
    .ant-list-item {
      padding: 8px 0;
    }
  }

  .ant-pagination-item {
    &-active {
      border-color: $tertiary;

      a {
        color: $tertiary;
      }
    }
    &:focus,
    &:hover {
      a {
        color: $tertiary;
      }
    }
  }

  .ant-pagination-prev {
    &:focus,
    &:hover {
      a {
        color: $tertiary;
      }
    }
  }

  .ant-pagination-next {
    &:focus,
    &:hover {
      a {
        color: $tertiary;
      }
    }
  }

  .ant-list {
    &-items {
      li:first-child {
        padding-top: 0;
      }
    }

    &-footer {
      padding-bottom: 0;

      .ant-row-flex {
        height: 100%;
      }

      .pagination {
        text-align: right;
      }
    }
  }

  .ant-list-item {
    &-meta {
      margin-bottom: 0;

      &-title {
        margin-bottom: 0;
      }
    }

    &-action {
      margin-top: 0;

      li {
        padding: 0;
      }
    }
  }

  .pending-item {
    padding-left: 8px !important;

    i {
      vertical-align: middle;
    }

    button {
      font-size: 22px;
      width: 30px;
    }
  }

  .material-icons {
    vertical-align: middle;
  }

  .favorite-project-list-item:hover {
    cursor: pointer;
  }
}

.base-card {
  .ant-card {
    &-head {
      font-size: 1.5rem;
      color: $secondary;
    }

    &-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    &-meta-description {
      font-size: 1.125rem;
    }
  }

  .action-button {
    color: $primary;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;

    &:focus,
    &:hover {
      background: $whitesmoke;
    }
  }

  .invoiced {
    font-size: 5rem;
    bottom: 122px;
  }

  .not-invoiced {
    font-size: 6rem;
    bottom: 110px;
    color: $light-gray-font;
  }

  .not-invoiced-disclaimer {
    font-size: 0.813rem;
    position: absolute;
    bottom: 12px;
  }

  .card-foot-disclaimer {
    font-size: 0.813rem;
  }

  .slim-divider {
    margin: 5px 0;
  }

  .ant-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.alert-box {
  margin-bottom: 15px;
}

.description-button-space {
  margin-bottom: 15px;
}

@media (max-width: $mobile-max) {
  .base-card {
    .ant-card {
      &-body {
        flex-direction: row;

        .general-card-total {
          margin-left: auto;
          font-size: 3rem;
          width: auto;
          div {
            font-size: 3rem !important;
          }
        }
      }

      &-meta {
        margin-right: auto;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media (min-width: $desktop-min) {
  .base-card {
    height: 391px;
    min-width: 250px;

    .ant-card {
      &-head {
        height: 53px;
      }

      &-body {
        height: 336px;
      }
    }

    .ant-spin-container {
      max-height: 280px;
    }

    .ant-spin-nested-loading {
      overflow: auto;
    }

    .invoiced.small {
      font-size: 3rem;
      bottom: 145px;
    }

    .not-invoiced.small {
      font-size: 5rem;
      bottom: 122px;
    }
  }

  .general-card-total {
    position: absolute;
    bottom: 72px;
    left: 0;
    right: 0;
  }
}
