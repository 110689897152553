@import './theme.scss';

@import './_structure.scss';
@import './_cards.scss';
@import './_pages.scss';
@import './_table.scss';

// /* Default and Mobile styles */

html {
  --antd-wave-shadow-color: $primary;
}

html,
body,
#root,
#root > div {
  height: 100%;
  width: 100%;
}

a,
a:link,
a:visited {
  color: $primary;
}

a:focus,
a:hover {
  text-decoration: underline;
}

a:focus,
a:active {
  color: $primary-dark;
}

.ant-breadcrumb .ant-breadcrumb-link a:hover {
  color: $primary;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:visited {
  background-color: $primary;
  border-color: $primary;
  color: white;
}

.ant-btn-primary:focus,
.ant-btn-primary:active {
  background-color: $primary-dark;
  border-color: $primary-dark;
  color: white;
}

.ant-btn-danger,
.ant-btn-danger:hover,
.ant-btn-danger:visited {
  background-color: $danger;
  border-color: $danger;
  color: white;
}

.ant-btn-danger:focus,
.ant-btn-danger:active {
  background-color: $danger-dark;
  border-color: $danger-dark;
  color: white;
}

.ant-btn-link,
.ant-btn-link:hover,
.ant-btn-link:visited {
  color: $primary;
}

.ant-btn-link:focus,
.ant-btn-link:active {
  color: $primary-dark;
}

.link-button,
.action {
  color: $primary;
  display: inline;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
.link-button:hover {
  text-decoration: underline;
  color: $primary;
}
.action:hover {
  text-decoration: none;
  color: $primary;
}
.link-button:active,
.link-button:focus,
.action:focus,
.action:active {
  color: $primary-dark;
}

.link-small {
  color: rgb(0, 40, 92) !important;
}

.link-small:hover {
  color: rgb(0, 40, 92) !important;
}

.action-button.link-small {
  color: rgb(0, 40, 92) !important;
}

.directory-contact-info {
  color: rgb(0, 40, 92) !important;
}
.directory-contact-info:hover {
  color: rgb(0, 40, 92) !important;
}

.red-text {
  color: $danger;
}

.desktop-only {
  display: none !important;

  @media (min-width: $desktop-min) {
    display: initial !important;
  }
}

.mobile-only {
  display: initial !important;

  @media (min-width: $desktop-min) {
    display: none !important;
  }
}
