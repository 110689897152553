.hide {
  display: none;
}

.ant-dropdown-menu-title-content .link-button {
  color: rgb(0, 40, 92) !important;
}

.ant-drawer {
  z-index: 1010 !important;
}

.impersonate-link {
  color: rgb(0, 40, 92) !important;
}

.ant-modal-wrap {
  z-index: 1011;
}

.main-sider {
  transition: all 0s !important;
  height: 100vh;
  position: fixed;
  left: 0;
}
.main-sider * {
  transition: all 0s !important;
}
.main-sider .ant-card-bordered {
  border-width: 1.5px;
}
.main-sider .ant-card-body {
  padding: 18px 12px;
  background-color: #f0f2f5;
}
.main-sider .impersonating-business-contact {
  font-size: 0.75rem;
  color: black;
}
.main-sider .impersonating-business-contact .ant-card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.main-sider .ant-layout {
  height: 100%;
}
.main-sider .ant-layout-header {
  background-color: #036db5;
  padding: 0 10px;
  text-align: center;
}
.main-sider .ant-layout-header a {
  color: white;
}
.main-sider .ant-layout-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.main-sider .ant-layout-content .ant-menu-submenu {
  color: #757575;
}
.main-sider .ant-layout-content .ant-menu-submenu:hover,
.main-sider .ant-layout-content .ant-menu-submenu-title:hover,
.main-sider .ant-layout-content .ant-menu-title-content:hover,
.main-sider .ant-layout-content .ant-menu-submenu-arrow:hover,
.main-sider .ant-layout-content .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.main-sider .ant-layout-content .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: rgb(0, 40, 92) !important;
}
.main-sider .ant-layout-content .ant-menu-submenu:active,
.main-sider .ant-layout-content .ant-menu-submenu-title:active {
  background: rgba(0, 40, 92, 0.05);
}
.main-sider .ant-layout-content .ant-menu-submenu::selection {
  background: rgba(0, 40, 92, 0.05);
}
.main-sider .ant-layout-content .ant-menu-title-content::selection {
  background: rgba(0, 40, 92, 0.05);
}
.main-sider .ant-layout-content .ant-menu {
  height: 100%;
}
.main-sider .ant-layout-content .ant-menu .ant-menu-item {
  color: rgb(0, 40, 92) !important;
}
.main-sider .ant-layout-content .ant-menu .ant-menu-item::after {
  border-right-color: rgb(0, 40, 92);
}
.main-sider .ant-layout-content .ant-menu .ant-menu-item:active {
  background-color: rgba(0, 40, 92, 0.05);
}
.main-sider .ant-layout-content .ant-menu .ant-menu-item:hover {
  color: rgb(0, 40, 92);
}
.main-sider .ant-layout-content .ant-menu .ant-menu-item a,
.main-sider .ant-layout-content .ant-menu .ant-menu-item a:link,
.main-sider .ant-layout-content .ant-menu .ant-menu-item a:visited,
.main-sider .ant-layout-content .ant-menu .ant-menu-item .link-button {
  color: #757575;
}
.main-sider .ant-layout-content .ant-menu .ant-menu-item a:focus,
.main-sider .ant-layout-content .ant-menu .ant-menu-item a:hover,
.main-sider .ant-layout-content .ant-menu .ant-menu-item .link-button:hover {
  text-decoration: none;
  color: rgb(0, 40, 92);
}
.main-sider .ant-layout-content .ant-menu .ant-menu-item-selected {
  background: rgba(0, 40, 92, 0.05);
  color: rgb(0, 40, 92);
}
.main-sider .ant-layout-content .ant-menu .ant-menu-item-selected a,
.main-sider .ant-layout-content .ant-menu .ant-menu-item-selected a:link,
.main-sider .ant-layout-content .ant-menu .ant-menu-item-selected a:visited {
  color: rgb(0, 40, 92);
}
.main-sider .ant-layout-content .ant-menu .ant-menu-item-selected::after {
  border-right-color: rgb(0, 40, 92);
}
.main-sider .ant-layout-content .ant-menu .link-button {
  width: 100%;
  text-align: left;
}
.main-sider .ant-layout-footer {
  padding: 0;
}
.main-sider .ant-layout-footer .sidebar-social-icons {
  font-size: 1.75rem;
}
.main-sider .ant-layout-footer .terms {
  font-size: 0.8rem;
}
.main-sider .ant-layout-footer .copyright {
  font-size: 0.625rem;
}
.main-sider .ant-layout-sider-zero-width-trigger {
  display: none;
}
.main-sider .ant-drawer-content-wrapper,
.main-sider .ant-drawer-content {
  width: 200px !important;
}
.main-sider .ant-drawer-body {
  height: 100%;
  padding: 0;
  width: 200px;
}
.main-sider .ant-drawer-body .ant-layout {
  height: 100%;
  margin-top: 0;
}

.ant-layout-header .anticon-caret-down {
  margin-left: 5px;
}

.user-dropdown-menu .ant-dropdown-menu-item-active {
  background: none;
}
.user-dropdown-menu a:hover,
.user-dropdown-menu button:hover {
  text-decoration: none;
}
.user-dropdown-menu a .anticon,
.user-dropdown-menu button .anticon {
  padding-right: 5px;
  margin-right: 3px;
}
.user-dropdown-menu .ant-dropdown-menu-item > button:hover,
.user-dropdown-menu .ant-dropdown-menu-submenu-title > button:hover {
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
}

.ant-dropdown-menu .anticon.anticon-usergroup-add.link-icon-sidebar {
  margin-right: 0px !important;
}

.link-icon-sidebar {
  margin-right: 0;
  min-width: auto;
  font-size: 14px;
  vertical-align: -0.125em;
}

.menu-link-icon .anticon {
  padding-right: 5px;
}

.main-section {
  min-height: 100vh;
}
@media (min-width: 1200px) {
  .main-section {
    margin-left: 200px;
  }
}
.main-section .main-header {
  background-color: #002b5c;
  padding: 0 35px;
  position: fixed;
  z-index: 1009;
  left: 0;
  top: 0;
  right: 0;
}
@media (max-width: 1199px) {
  .main-section .main-header {
    padding: 0 15px;
  }
}
@media (min-width: 1200px) {
  .main-section .main-header {
    left: 200px;
  }
}
.main-section .main-header .main-app-bar {
  color: white;
  height: 100%;
}
.main-section .main-header .main-app-bar > div {
  height: 100%;
}
.main-section .main-header .main-app-bar .left {
  font-size: 1.25rem;
}
.main-section .main-header .main-app-bar .left .link-button {
  color: white;
}
.main-section .main-header .main-app-bar .middle {
  text-align: center;
}
.main-section .main-header .main-app-bar .middle a,
.main-section .main-header .main-app-bar .middle a:link,
.main-section .main-header .main-app-bar .middle a:visited {
  color: white;
}
.main-section .main-header .main-app-bar .middle img {
  width: auto;
  height: 64px;
}
@media (max-width: 1199px) {
  .main-section .main-header .main-app-bar .middle img {
    height: 48px;
  }
}
.main-section .main-header .main-app-bar .right {
  text-align: right;
}
.main-section .main-header .main-app-bar .right a {
  padding: 2.5px;
}
.main-section .main-header .main-app-bar .right a.alert-action {
  color: #dd6675;
}
.main-section .main-header .main-app-bar .right a.alert-action:active {
  color: #bc2a3c;
}
.main-section .main-header .main-app-bar .material-icons {
  vertical-align: middle;
}
.main-section .main-content {
  margin: 15px;
  margin-top: 79px;
  height: 100%;
}
.main-section .main-content > div {
  height: 100%;
}
@media (min-width: 1200px) {
  .main-section .main-content {
    min-width: 840px;
  }
}

.ant-drawer-body > :first-child {
  margin-top: 35px;
}

/* Site loading animation */
.loading-container {
  height: 100vh;
  width: 100vw;
  z-index: 1;
  position: fixed;
  overflow-x: hidden;
}
.loading-container .loading-content {
  position: relative;
  text-align: center;
  top: 45%;
}

.ant-spin {
  color: #b2bb1c;
}
.ant-spin .ant-spin-dot .ant-spin-dot-item {
  background-color: #b2bb1c;
}

@media (max-width: 1199px) {
  .show-on-mobile {
    display: block;
  }
}
@media (min-width: 1200px) {
  .show-on-mobile {
    display: none;
  }
}

@media (max-width: 1199px) {
  .hide-on-mobile {
    display: none;
  }
}
@media (min-width: 1200px) {
  .hide-on-mobile {
    display: block;
  }
}

.full-width-drawer .ant-drawer-header {
  position: absolute;
  width: 100%;
  z-index: 2;
  background-color: #fff;
}
.full-width-drawer .ant-drawer-body {
  margin-top: 40px;
}

.switch-container {
  padding-top: 3px;
  padding-bottom: 3px;
  display: block;
  width: 100%;
}
.switch-container .switch-label {
  margin-left: 5px;
  font-size: "small";
}

.general-card-total {
  color: #b2bb1c;
  font-size: 9rem;
  text-align: center;
  text-decoration: none !important;
  width: 100%;
}

.card-total-red {
  color: #dd6675;
}

.directory-contact-info {
  color: #b2bb1c;
}

.base-list.ant-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.base-list.ant-list-sm .ant-list-item {
  padding: 8px 0;
}
.base-list .ant-pagination-item-active {
  border-color: #036db5;
}
.base-list .ant-pagination-item-active a {
  color: #036db5;
}
.base-list .ant-pagination-item:focus a, .base-list .ant-pagination-item:hover a {
  color: #036db5;
}
.base-list .ant-pagination-prev:focus a, .base-list .ant-pagination-prev:hover a {
  color: #036db5;
}
.base-list .ant-pagination-next:focus a, .base-list .ant-pagination-next:hover a {
  color: #036db5;
}
.base-list .ant-list-items li:first-child {
  padding-top: 0;
}
.base-list .ant-list-footer {
  padding-bottom: 0;
}
.base-list .ant-list-footer .ant-row-flex {
  height: 100%;
}
.base-list .ant-list-footer .pagination {
  text-align: right;
}
.base-list .ant-list-item-meta {
  margin-bottom: 0;
}
.base-list .ant-list-item-meta-title {
  margin-bottom: 0;
}
.base-list .ant-list-item-action {
  margin-top: 0;
}
.base-list .ant-list-item-action li {
  padding: 0;
}
.base-list .pending-item {
  padding-left: 8px !important;
}
.base-list .pending-item i {
  vertical-align: middle;
}
.base-list .pending-item button {
  font-size: 22px;
  width: 30px;
}
.base-list .material-icons {
  vertical-align: middle;
}
.base-list .favorite-project-list-item:hover {
  cursor: pointer;
}

.base-card .ant-card-head {
  font-size: 1.5rem;
  color: #002b5c;
}
.base-card .ant-card-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.base-card .ant-card-meta-description {
  font-size: 1.125rem;
}
.base-card .action-button {
  color: #b2bb1c;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}
.base-card .action-button:focus, .base-card .action-button:hover {
  background: #efefef;
}
.base-card .invoiced {
  font-size: 5rem;
  bottom: 122px;
}
.base-card .not-invoiced {
  font-size: 6rem;
  bottom: 110px;
  color: #9b9b9b;
}
.base-card .not-invoiced-disclaimer {
  font-size: 0.813rem;
  position: absolute;
  bottom: 12px;
}
.base-card .card-foot-disclaimer {
  font-size: 0.813rem;
}
.base-card .slim-divider {
  margin: 5px 0;
}
.base-card .ant-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.alert-box {
  margin-bottom: 15px;
}

.description-button-space {
  margin-bottom: 15px;
}

@media (max-width: 1199px) {
  .base-card .ant-card-body {
    flex-direction: row;
  }
  .base-card .ant-card-body .general-card-total {
    margin-left: auto;
    font-size: 3rem;
    width: auto;
  }
  .base-card .ant-card-body .general-card-total div {
    font-size: 3rem !important;
  }
  .base-card .ant-card-meta {
    margin-right: auto;
    display: flex;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .base-card {
    height: 391px;
    min-width: 250px;
  }
  .base-card .ant-card-head {
    height: 53px;
  }
  .base-card .ant-card-body {
    height: 336px;
  }
  .base-card .ant-spin-container {
    max-height: 280px;
  }
  .base-card .ant-spin-nested-loading {
    overflow: auto;
  }
  .base-card .invoiced.small {
    font-size: 3rem;
    bottom: 145px;
  }
  .base-card .not-invoiced.small {
    font-size: 5rem;
    bottom: 122px;
  }
  .general-card-total {
    position: absolute;
    bottom: 72px;
    left: 0;
    right: 0;
  }
}
.autocomplete {
  width: 200px;
  top: 32px;
  left: 0;
  width: 100%;
}

.autocomplete-container {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.drawer-dropdown {
  z-index: 1012 !important;
}

.not-drawer-dropdown {
  z-index: 1008 !important;
}

.ant-dropdown {
  background-color: #fff !important;
}

.page-card {
  height: 100%;
}
.page-card .ant-card-head {
  color: #002b5c;
}

.ant-menu-title-content .anticon {
  padding-right: 15px;
}

.filters-card .ant-card-head {
  color: #002b5c;
}

.dashboard-controls-wrapper,
.dashboard-controls,
.dashboard-spacer {
  display: none;
}

.dashboard-controls .edit-icon {
  cursor: pointer;
}

.sortable-card .overlay {
  position: absolute;
  top: 7.5px;
  bottom: 7.5px;
  left: 7.5px;
  right: 7.5px;
  opacity: 0.5;
  background: white;
}
.sortable-card .trash {
  position: absolute;
  right: 7.5px;
  top: 7.5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background: white;
  cursor: pointer;
}
.sortable-card .handle {
  position: absolute;
  margin: auto;
  left: 7.5px;
  right: 7.5px;
  top: 7.5px;
  bottom: 7.5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background: white;
  cursor: pointer;
}
.sortable-card .avatar-icon {
  vertical-align: middle;
}

@media (max-width: 1199px) {
  .sortable-card {
    width: 100%;
  }
}
.user-select-none {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.edit-popover-checklist {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  height: 350px;
}
.edit-popover-checklist label:first-child {
  margin-left: 8px;
}
.edit-popover-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  width: 300px;
}

@media (min-width: 1200px) {
  .dashboard-controls-wrapper {
    display: block;
  }
  .dashboard-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .dashboard-controls button {
    margin-right: 25px;
  }
  .dashboard-controls .link-button {
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 3px;
    padding: 3px;
    border-radius: 4px;
    outline: none;
  }
  .dashboard-controls .link-button:hover, .dashboard-controls .link-button:focus {
    background-color: rgba(0, 0, 0, 0.07);
  }
  .dashboard-controls .edit-icon {
    margin-left: 5px;
    margin-top: 3px;
    padding: 3px;
    border-radius: 4px;
  }
  .dashboard-controls .edit-icon:hover, .dashboard-controls .edit-icon:focus {
    background-color: rgba(0, 0, 0, 0.07);
  }
  .dashboard-spacer {
    display: block;
  }
  .dashboard-site-view {
    width: 25%;
  }
}
.mobile-cards {
  display: block;
}

.load-more {
  margin-top: 25px;
  width: 100%;
}

.ant-calendar-picker {
  width: 100%;
}

.invoices-filters button {
  width: 100%;
}

.invoice-table-tags-container {
  min-height: auto;
  padding: 10px 20px 20px 20px;
}

.table-tags-container {
  min-height: auto;
  padding-bottom: 10px;
}
.table-tags-container:empty {
  display: none;
}
.table-tags-container .table-tags {
  padding: 10px 0px 0px 10px;
}
.table-tags-container .table-tags > span:first-child {
  margin-right: 10px;
}
.table-tags-container .table-tags .ant-tag {
  margin-bottom: 5px;
}

.export-to-csv {
  float: right;
}

.ant-list-items {
  background-color: #fff;
}

.mobile-table-head {
  background-color: #f6f6f6;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.mobile-table-head .project-number {
  font-size: 1.5rem;
  color: #b2bb1c;
}
.mobile-table-head .project-work-site {
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #a0a0a0;
  font-weight: 400;
}

.active-project {
  border-left: 5px solid #b2bb1c;
}

.closed-project {
  border-left: 5px solid #000;
}

.mobile-table-fields {
  padding-top: 10px;
}

.ant-table-summary {
  font-weight: bold;
}

.mobile-table-metrics .mobile-table-metric {
  display: table;
  width: 100%;
  margin-top: 15px;
}
.mobile-table-metrics .mobile-table-metric .metric-label {
  display: table-cell;
  font-size: 1rem;
  color: #4a4a4a;
}
.mobile-table-metrics .mobile-table-metric .metric-value {
  display: table-cell;
  text-align: right;
  font-size: 1rem;
  color: #000;
}

.project-table-link {
  color: rgba(0, 0, 0, 0.65) !important;
}

.project-table-link:hover {
  text-decoration: none;
}

.mobile-table-metrics-totals {
  background-color: #ffffdc;
  padding: 5px;
  margin-top: 15px;
}
.mobile-table-metrics-totals div:first-child {
  margin-top: 5px;
}

.mobile-table-metrics-last-date {
  background-color: #ddf3ff;
  padding: 5px;
  margin-top: 15px;
}
.mobile-table-metrics-last-date div:first-child {
  margin-top: 5px;
}

.custom-filter-checkbox {
  display: flex;
  flex-direction: column;
  padding: 4px 0;
}
.custom-filter-checkbox label.ant-checkbox-wrapper {
  padding: 5px 12px;
  margin: 0;
}
.custom-filter-checkbox label.ant-checkbox-wrapper:hover {
  background-color: #f5f5f5;
}

.custom-filter-buttons {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f0f0f0;
  padding: 7px 8px;
}

.project-cards-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 0;
  background: #f0f2f5;
  padding: 20px;
  min-height: 100vh;
}

.ant-descriptions-item-label,
.ant-descriptions-item-content {
  font-size: 12px;
}

.card-view-card .ant-card-head-title {
  color: #002b5c;
}

.card-view-yellow {
  background-color: #ffffdc;
  padding: 2px;
}

.card-view-blue {
  background-color: #ddf3ff;
  padding: 2px;
}

.fullscreen-settings {
  position: absolute;
  top: -35px;
  right: 25px;
  font-size: 28px;
  color: #b2bb1c;
}

.ant-dropdown-menu-item:hover .link-button {
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
}

.release-note ul {
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .invoice-table,
  .project-table {
    display: block;
  }
  .mobile-cards {
    display: none;
  }
  .invoices-filters button {
    width: auto;
  }
  .table-tags-container {
    min-height: 46px;
  }
}
.landing-section {
  height: 100%;
}
.landing-section .landing-header {
  background-color: #002b5c;
  padding: 0 35px;
}
@media (max-width: 1199px) {
  .landing-section .landing-header {
    padding: 0 15px;
  }
}
.landing-section .landing-header .landing-app-bar {
  color: white;
  height: 100%;
}
.landing-section .landing-header .landing-app-bar > div {
  height: 100%;
}
.landing-section .landing-header .landing-app-bar .left {
  font-size: 1.25rem;
}
.landing-section .landing-header .landing-app-bar .left .link-button {
  color: white;
}
.landing-section .landing-header .landing-app-bar .middle {
  text-align: center;
}
.landing-section .landing-header .landing-app-bar .middle a,
.landing-section .landing-header .landing-app-bar .middle a:link,
.landing-section .landing-header .landing-app-bar .middle a:visited {
  color: white;
}
.landing-section .landing-header .landing-app-bar .middle img {
  width: auto;
  height: 64px;
}
@media (max-width: 1199px) {
  .landing-section .landing-header .landing-app-bar .middle img {
    height: 48px;
  }
}
.landing-section .landing-header .landing-app-bar .right {
  text-align: right;
}
.landing-section .landing-content {
  background-image: url("./welder.jpg");
  background-position: center;
  background-size: cover;
  background-blend-mode: luminosity;
  background-color: #002b5c;
  height: 100%;
}
.landing-section .landing-content .landing-message {
  color: white;
  font-size: 3em;
  line-height: 0.75;
}
@media (max-width: 1199px) {
  .landing-section .landing-content .landing-message {
    line-height: 1.25;
    margin-bottom: 0.3em;
  }
}
.landing-section .landing-call-to-action {
  text-align: center;
}
@media (min-width: 1200px) {
  .landing-section .landing-call-to-action button {
    font-size: 2em;
    height: 60px;
  }
}

.landing-footer .left .landing-social-icons {
  font-size: 3.5em;
  max-width: 350px;
}
.landing-footer .left .landing-social-icons a {
  color: rgb(0, 40, 92);
}
@media (max-width: 1199px) {
  .landing-footer .left .landing-social-icons {
    font-size: 2.625em;
    margin: auto;
  }
}
.landing-footer .left .landing-terms {
  font-size: 1.25em;
  max-width: 350px;
}
.landing-footer .left .landing-terms a {
  color: rgb(0, 40, 92);
}
@media (max-width: 1199px) {
  .landing-footer .left .landing-terms {
    font-size: 1em;
    margin: auto;
  }
}
@media (max-width: 1199px) {
  .landing-footer .left {
    text-align: center;
  }
}
.landing-footer .right {
  margin-top: auto;
  text-align: right;
}
.landing-footer .right p {
  margin: 0;
}
@media (max-width: 1199px) {
  .landing-footer .right {
    text-align: center;
  }
}

.landing-menu .ant-row {
  margin-top: 5px;
}
.landing-menu .ant-menu {
  margin-top: 10px;
  font-size: 1.15em;
}
.landing-menu img {
  width: auto;
  height: 64px;
  margin-left: 11px;
}
@media (max-width: 1199px) {
  .landing-menu img {
    height: 48px;
  }
}

.echo-images .ant-col {
  text-align: center;
}
.echo-images .main-image {
  height: 350px;
  width: 350px;
  object-fit: cover;
  text-indent: -10000px;
}
@media (max-width: 1199px) {
  .echo-images .main-image {
    height: 225px;
    width: 225px;
  }
}
.echo-images .placeholder span {
  height: 350px;
  width: 350px;
}
@media (max-width: 1199px) {
  .echo-images .placeholder span {
    height: 225px;
    width: 225px;
  }
}
.echo-images .navigation-btn span {
  font-size: 36px;
}
.echo-images .sub-image-row {
  max-height: 160px;
  overflow-y: auto;
  object-fit: cover;
}
@media (max-width: 1199px) {
  .echo-images .sub-image-row {
    max-height: 90px;
  }
}
.echo-images .sub-image-row .placeholder span {
  max-height: 160px;
}
@media (max-width: 1199px) {
  .echo-images .sub-image-row .placeholder span {
    max-height: 90px;
  }
}
.echo-images .sub-image-btn {
  border: 3px solid transparent;
  background: none;
  padding: 3px;
}
.echo-images .sub-image-btn div.ant-image {
  vertical-align: middle;
}
.echo-images .sub-image-btn.active-image {
  border: 3px solid #b2bb1c;
}
.echo-images .sub-image {
  height: 96px;
  width: 96px;
  text-indent: -10000px;
}
@media (max-width: 1199px) {
  .echo-images .sub-image {
    height: 48px;
    width: 48px;
  }
}

.table-card .ant-card-body {
  padding: 0;
}

.ant-table-pagination {
  margin-right: 20px !important;
}

.ant-table-thead > tr > th {
  font-size: 0.75rem;
  white-space: nowrap;
  padding: 16px 20px 16px 24px;
}

.ant-table-tbody > tr > td,
.ant-table-summary .ant-table-cell {
  font-size: 0.8125rem;
  padding: 16px 20px 16px 24px;
}

.ant-table-tbody > tr.highlighted {
  background-color: #fbfce9;
}

.table-filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
}
.table-filters-wrapper .table-filters-subtitle {
  width: 100%;
}
.table-filters-wrapper .table-filters-col {
  width: 100%;
}
.table-filters-wrapper .table-filter {
  margin-top: 4px;
  margin-bottom: 4px;
  display: inline-block;
}
.table-filters-wrapper .filters-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-flow: wrap-reverse;
}
.table-filters-wrapper .filters-buttons .search-button {
  margin-right: 5px;
}
.table-filters-wrapper .reset-visibility {
  margin: 10px 0;
}

.report-buttons {
  text-align: right;
}
.report-buttons button {
  margin: 5px;
}

.report-table.summary-table .ant-table-title {
  padding: 0;
}
.report-table .ant-divider {
  margin: 18px 0 !important;
}
.report-table h1 {
  font-size: 1.5rem !important;
  margin-bottom: 0 !important;
}

.report-filters .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.report-filters .ant-form-item-label label {
  font-size: 1rem;
  font-weight: 500;
}
.report-filters .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: "";
  margin-right: 0px;
}
.report-filters .ant-checkbox + span {
  padding-right: 0px;
}
.report-filters .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

th.table-actions.ant-table-cell {
  color: transparent;
  user-select: none;
}

th.ant-table-cell.groupless-column {
  vertical-align: bottom;
}
th.ant-table-cell.groupless-column .anticon-search {
  top: 76%;
}

.ant-table-cell.ant-table-cell-ellipsis {
  max-width: 175px;
}

.table-actions {
  text-align: center;
}
.table-actions > div {
  max-width: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-actions span {
  font-size: 17px;
  margin-top: 0 !important;
}

tr.table-active-project > td.table-actions {
  border-left: solid #b2bb1c 3px;
}

tr.table-closed-project > td.table-actions {
  border-left: solid black 3px;
}

tr.table-project-column-updated-inspected > td.inspected {
  animation-iteration-count: 1;
  -webkit-animation: change-color 6s forwards;
  -moz-animation: change-color 6s forwards;
  -o-animation: change-color 6s forwards;
  animation: change-color 6s forwards;
}

tr.table-project-column-updated-good > td.good {
  animation-iteration-count: 1;
  -webkit-animation: change-color 6s forwards;
  -moz-animation: change-color 6s forwards;
  -o-animation: change-color 6s forwards;
  animation: change-color 6s forwards;
}

tr.table-project-column-updated-reworked > td.reworked {
  animation-iteration-count: 1;
  -webkit-animation: change-color 6s forwards;
  -moz-animation: change-color 6s forwards;
  -o-animation: change-color 6s forwards;
  animation: change-color 6s forwards;
}

tr.table-project-column-updated-defects > td.defects {
  animation-iteration-count: 1;
  -webkit-animation: change-color 6s forwards;
  -moz-animation: change-color 6s forwards;
  -o-animation: change-color 6s forwards;
  animation: change-color 6s forwards;
}

tr.table-project-column-updated-fallout > td.fallout {
  animation-iteration-count: 1;
  -webkit-animation: change-color 6s forwards;
  -moz-animation: change-color 6s forwards;
  -o-animation: change-color 6s forwards;
  animation: change-color 6s forwards;
}

tr.table-project-column-updated-lastSortDate > td.lastSortDate {
  animation-iteration-count: 1;
  -webkit-animation: change-color 6s forwards;
  -moz-animation: change-color 6s forwards;
  -o-animation: change-color 6s forwards;
  animation: change-color 6s forwards;
}

tr.table-project-column-updated-lastSortDateInspectedTotal > td.lastSortDateInspectedTotal {
  animation-iteration-count: 1;
  -webkit-animation: change-color 6s forwards;
  -moz-animation: change-color 6s forwards;
  -o-animation: change-color 6s forwards;
  animation: change-color 6s forwards;
}

tr.table-project-column-updated-lastSortDateNonConformTotal > td.lastSortDateNonConformTotal {
  animation-iteration-count: 1;
  -webkit-animation: change-color 6s forwards;
  -moz-animation: change-color 6s forwards;
  -o-animation: change-color 6s forwards;
  animation: change-color 6s forwards;
}

tr.table-project-column-updated-lastSortDateReworkTotal > td.lastSortDateReworkTotal {
  animation-iteration-count: 1;
  -webkit-animation: change-color 6s forwards;
  -moz-animation: change-color 6s forwards;
  -o-animation: change-color 6s forwards;
  animation: change-color 6s forwards;
}

tr.table-project-column-updated-lastSortDateGoodTotal > td.lastSortDateGoodTotal {
  animation-iteration-count: 1;
  -webkit-animation: change-color 6s forwards;
  -moz-animation: change-color 6s forwards;
  -o-animation: change-color 6s forwards;
  animation: change-color 6s forwards;
}

@keyframes change-color {
  0% {
    background-color: #fff;
  }
  10% {
    background-color: #ddf3ff;
  }
  100% {
    background-color: #fff;
  }
}
@-webkit-keyframes change-color {
  0% {
    background-color: #fff;
  }
  10% {
    background-color: #ddf3ff;
  }
  100% {
    background-color: #fff;
  }
}
th.table-group-gold.ant-table-cell {
  background: #fff8dc;
}
th.table-group-gold.ant-table-cell.ant-table-column-sort {
  background: #fff5cd;
}
th.table-group-gold.ant-table-cell:hover {
  background: #fff2bf;
}
th.table-group-gold.ant-table-cell:hover .ant-table-filter-trigger-container {
  background: #fff5cd;
}
th.table-group-gold.ant-table-cell .ant-table-filter-trigger-container:hover {
  background: #f2e6b2 !important;
}
th.table-group-yellow.ant-table-cell {
  background: #ffffdc;
}
th.table-group-yellow.ant-table-cell.ant-table-column-sort {
  background: #ffffcd;
}
th.table-group-yellow.ant-table-cell:hover {
  background: #ffffbf;
}
th.table-group-yellow.ant-table-cell:hover .ant-table-filter-trigger-container {
  background: #ffffcd;
}
th.table-group-yellow.ant-table-cell .ant-table-filter-trigger-container:hover {
  background: #f2f2b2 !important;
}
th.table-group-cyan.ant-table-cell {
  background: #dafaf6;
}
th.table-group-cyan.ant-table-cell.ant-table-column-sort {
  background: #cbf8f2;
}
th.table-group-cyan.ant-table-cell:hover {
  background: #bcf6ee;
}
th.table-group-cyan.ant-table-cell:hover .ant-table-filter-trigger-container {
  background: #cbf8f2;
}
th.table-group-cyan.ant-table-cell .ant-table-filter-trigger-container:hover {
  background: #b0e9e1 !important;
}
th.table-group-blue.ant-table-cell {
  background: #ddf3ff;
}
th.table-group-blue.ant-table-cell.ant-table-column-sort {
  background: #cfeeff;
}
th.table-group-blue.ant-table-cell:hover {
  background: #c1e9ff;
}
th.table-group-blue.ant-table-cell:hover .ant-table-filter-trigger-container {
  background: #cfeeff;
}
th.table-group-blue.ant-table-cell .ant-table-filter-trigger-container:hover {
  background: #b4ddf2 !important;
}

.table-uri-icon:hover {
  text-decoration: none;
}

html {
  --antd-wave-shadow-color: $primary;
}

html,
body,
#root,
#root > div {
  height: 100%;
  width: 100%;
}

a,
a:link,
a:visited {
  color: #b2bb1c;
}

a:focus,
a:hover {
  text-decoration: underline;
}

a:focus,
a:active {
  color: #6a6f11;
}

.ant-breadcrumb .ant-breadcrumb-link a:hover {
  color: #b2bb1c;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:visited {
  background-color: #b2bb1c;
  border-color: #b2bb1c;
  color: white;
}

.ant-btn-primary:focus,
.ant-btn-primary:active {
  background-color: #6a6f11;
  border-color: #6a6f11;
  color: white;
}

.ant-btn-danger,
.ant-btn-danger:hover,
.ant-btn-danger:visited {
  background-color: #dd6675;
  border-color: #dd6675;
  color: white;
}

.ant-btn-danger:focus,
.ant-btn-danger:active {
  background-color: #bc2a3c;
  border-color: #bc2a3c;
  color: white;
}

.ant-btn-link,
.ant-btn-link:hover,
.ant-btn-link:visited {
  color: #b2bb1c;
}

.ant-btn-link:focus,
.ant-btn-link:active {
  color: #6a6f11;
}

.link-button,
.action {
  color: #b2bb1c;
  display: inline;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.link-button:hover {
  text-decoration: underline;
  color: #b2bb1c;
}

.action:hover {
  text-decoration: none;
  color: #b2bb1c;
}

.link-button:active,
.link-button:focus,
.action:focus,
.action:active {
  color: #6a6f11;
}

.link-small {
  color: rgb(0, 40, 92) !important;
}

.link-small:hover {
  color: rgb(0, 40, 92) !important;
}

.action-button.link-small {
  color: rgb(0, 40, 92) !important;
}

.directory-contact-info {
  color: rgb(0, 40, 92) !important;
}

.directory-contact-info:hover {
  color: rgb(0, 40, 92) !important;
}

.red-text {
  color: #dd6675;
}

.desktop-only {
  display: none !important;
}
@media (min-width: 1200px) {
  .desktop-only {
    display: initial !important;
  }
}

.mobile-only {
  display: initial !important;
}
@media (min-width: 1200px) {
  .mobile-only {
    display: none !important;
  }
}

