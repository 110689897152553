$primary: #b2bb1c;
$primary-dark: #6a6f11;
$secondary: #002b5c;
$tertiary: #036db5;
$danger-light: #e7928f;
$danger: #dd6675;
$danger-dark: #bc2a3c;
$white-active: #d9d9d9;
$off-white: #fbfbfb;
$dashboard-white: #f6f6f6;
$light-gray-font: #9b9b9b;
$dark-gray: #a0a0a0;
$dark-slate-gray: #4a4a4a;
$nav-gray-font: #757575;
$perrywinkle: #8a9ee0;
$gainsboro: #e5e5e5;
$black: #000;
$whitesmoke: #efefef;
$yellow-active: #fbfce9;
$ant-background-gray: #f0f2f5;
$desktop-min: 1200px;
$mobile-max: 1199px;
